import { useState, useEffect, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValid } from "date-fns";
import styled from "styled-components";

import { useUpdateTaskPart } from "../task/mutations/useUpdateTaskPart";
import { TaskPartStatus } from "../../api/core/taskAPI";
import QueryWrapper from "../../components/application/QueryWrapper";

import MainArea from "../../components/layout/MainArea";
import { H1, TB } from "../../components/texts";
import { InputWithLabel, SelectForm, InputCheckboxClean } from "../../components/inputs";
import { CleanButton, SecondaryButton } from "../../components/buttons";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import TaskEdit from "../task/TaskEdit";
import { standardDateOnly } from "../../common/date";
import { Horizontal } from "../../components/layout/FlexGrid";
import { useRequisitions } from "../task/hooks/useRequisitions";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";
import { useExternalWithdrawParts } from "./hooks/useExternalWithdrawParts";
import { useUpdateExternalPart } from "../task/mutations/useUpdateExternalPart";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import WorkshopSelect from "../../components/application/workshop/WorkshopSelect";
import { useNavigate } from "react-router";
import { useSearchParams } from "../application/hooks/useSearchParams";
import {
    getLastUsedWithdrawalWorkshopId,
    saveLastUsedWithdrawalWorkshopId,
} from "../../app/localStorage";

const InventoryWithdrawal = () => {
    const externalWithdrawParts = useExternalWithdrawParts(); // Fetching all parts from external withdrawel

    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const methods = useForm();

    const tasks = useRequisitions();
    const { mutate: updateTaskPart } = useUpdateTaskPart();
    const { mutate: updateExternalPart } = useUpdateExternalPart();
    const { data: customers } = useCustomers();

    const search = methods.watch("search");
    const checkboxes = methods.watch("checkboxes");
    const statusFilterValue = methods.watch("status")?.value || "all_completed_orders";
    const selectedRequests = checkboxes?.filter((item) => item.checked);

    const { getParam } = useSearchParams();
    const workshopId = getParam("workshopId") ?? "-1";

    useEffect(() => {
        if (workshopId) {
            saveLastUsedWithdrawalWorkshopId(workshopId);
            return;
        }

        getLastUsedWithdrawalWorkshopId().then((lastUsedWorkshopId) => {
            if (!lastUsedWorkshopId) return;
            navigate(`/materials/inventory-withdrawal?workshopId=${lastUsedWorkshopId}`);
        });
    }, [workshopId]);

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 30,
            },
        }),
        []
    );

    const data = useMemo(() => {
        const allRequestedParts = [];
        for (const task of tasks?.data ?? []) {
            if (task.parts?.length == null) continue;
            if (
                workshopId != null &&
                workshopId !== "-1" &&
                task.host_id.toString() !== workshopId
            ) {
                continue;
            }

            for (const part of task.parts) {
                const count = part?.count || 1;
                let status = "reserved";
                if (part?.status === "paid") {
                    status = "invoiced";
                } else if (task.status === "completed") {
                    status = "withdrawal";
                }

                allRequestedParts.push({
                    ...task,
                    requestedPart: {
                        ...part,
                        count,
                        cost: part?.part?.price ? part.part.price * count : null,
                        status,
                    },
                    isExternal: false,
                });
            }
        }

        externalWithdrawParts?.data?.forEach((external) => {
            let status = "reserved";
            if (external.status === "withdrawn") {
                status = "withdrawal";
            }

            allRequestedParts.push({
                id: external.id,
                start: external.createdAt,
                workorder_id: external.order_nr,
                unit: {
                    int_id: external.vehicle_nr,
                },
                customer_ref: t("inventory_withdrawal_customer"),
                requestedPart: {
                    // id: null,
                    // cost: null,
                    count: external.amount,
                    // empty: false,
                    // eta: null,
                    // invoice_number: null,
                    part: {
                        description: external.part_name,
                        // id: null,
                        name: external.part_nr,
                        // price: null,
                        stock: true,
                        // supplier_id: null,
                    },
                    // part_id: null,
                    status,
                },
                isExternal: true,
            });
        });

        const allRequestsInStock = allRequestedParts.filter(
            (item) => item.requestedPart?.part?.stock
        );
        const requestsWithSelectedStatus = allRequestsInStock?.reduce((acc, item) => {
            if (statusFilterValue === "all_completed_orders") {
                if (item.requestedPart.status === "invoiced") acc.push(item);
                if (item.requestedPart.status === "withdrawal") acc.push(item);
            } else if (statusFilterValue === "reserved") {
                if (item.requestedPart.status === "reserved") acc.push(item);
            } else if (statusFilterValue === "withdrawal") {
                if (item.requestedPart.status === "withdrawal") acc.push(item);
            } else if (statusFilterValue === "invoiced") {
                if (item.requestedPart.status === "invoiced") acc.push(item);
            }

            return acc;
        }, []);

        return requestsWithSelectedStatus;
    }, [tasks?.data, externalWithdrawParts?.data, workshopId, statusFilterValue]);

    // Add request ids to row checkbox values
    useEffect(() => {
        const allRequestIds =
            data?.map((part) => ({
                isExternal: part.isExternal,
                requestId: part.isExternal ? part.id : part.requestedPart.id,
                checked: false,
            })) || [];
        methods.setValue("checkboxes", allRequestIds);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                header: "",
                id: "checkbox",
                accessorFn: (row) => (row.isExternal ? row.id : row.requestedPart.id),
                cell: ({ row, getValue }) => {
                    return (
                        <CheckboxInputContainer>
                            <InputCheckboxClean
                                id={
                                    row.original.isExternal
                                        ? `check-external-${getValue()}`
                                        : `check-${getValue()}`
                                }
                                {...methods.register(`checkboxes.${row.id}.checked`)}
                            />
                        </CheckboxInputContainer>
                    );
                },
                enableSorting: false,
            },
            {
                header: t("status"),
                accessorKey: "requestedPart.status",
                cell: ({ getValue }) => t(`status-${getValue()}`),
            },
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value && isValid(new Date(value)) ? standardDateOnly(value) : "";
                },
            },
            {
                header: t("order"),
                accessorKey: "workorder_id",
                cell: ({ getValue, row }) =>
                    row.original.isExternal ? (
                        getValue()
                    ) : (
                        <CleanButton
                            type="button"
                            onClick={() => openEditorForTask(row.original.id)}
                        >
                            <TB $link>{getValue()}</TB>
                        </CleanButton>
                    ),
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
            },
            {
                header: t("customer_ref"),
                accessorKey: "customer_ref",
            },
            {
                header: t("part_name"),
                accessorKey: "requestedPart.part.name",
                cell: ({ row, getValue }) => {
                    if (row.original.isExternal) return getValue();
                    return <TB $link>{getValue()}</TB>;
                },
            },
            {
                header: t("description_2"),
                accessorKey: "requestedPart.part.description",
                id: "description",
                enableSorting: false,
            },
            {
                header: t("quantity"),
                accessorKey: "requestedPart.count",
                enableSorting: false,
            },
            {
                header: t("empty"),
                accessorKey: "requestedPart.empty",
                enableSorting: false,
                cell: ({ row, getValue }) =>
                    row.original.isExternal ? "-" : getValue() ? t("yes") : t("no"),
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        initialState,
        autoResetGlobalFilter: false,
        autoResetSortBy: false,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function openEditorForTask(id) {
        setEditTaskId(id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    function onStatusUpdate(newStatus) {
        if (selectedRequests?.length) {
            Promise.all(
                selectedRequests.map((part) =>
                    part.isExternal
                        ? updateExternalPart({ id: part.requestId, status: newStatus })
                        : updateTaskPart({ id: part.requestId, status: newStatus })
                )
            );
        }
    }

    return (
        <MainArea>
            <Header>
                <H1>{t("inventory_withdrawal")}</H1>
            </Header>

            <FormProvider {...methods}>
                <form>
                    {customers?.length > 1 ? (
                        <SelectWrapper>
                            <WorkshopSelect
                                defaultWorkshopId={+workshopId}
                                allowAll
                                onChange={(e) => {
                                    navigate(
                                        `/materials/inventory-withdrawal?workshopId=${e.value}`
                                    );
                                }}
                            />
                        </SelectWrapper>
                    ) : null}

                    <InputWithLabel
                        name="search"
                        label={t("search_inventory_withdrawal")}
                        style={{ maxWidth: "27rem" }}
                    />

                    <OptionsRow>
                        <SelectStyled
                            name={`status`}
                            defaultValue={{
                                value: "all_completed_orders",
                                label: t("all_completed_orders"),
                            }}
                            label={t("show_status")}
                            options={[
                                { value: "all_completed_orders", label: t("all_completed_orders") },
                                { value: "reserved", label: t("status-reserved") },
                                { value: "withdrawal", label: t("status-withdrawal") },
                                { value: "invoiced", label: t("status-invoiced") },
                            ]}
                        />

                        <StatusActionButton
                            onClick={() => onStatusUpdate(TaskPartStatus.PAID)}
                            disabled={!selectedRequests?.length}
                        >
                            {t("mark_as_invoiced")}
                        </StatusActionButton>
                    </OptionsRow>

                    <QueryWrapper data={[tasks, externalWithdrawParts]}>
                        {rowModel.rows.length > 0 ? (
                            <TableContainer>
                                <TableStyled>
                                    <StandardTableContent
                                        headerGroups={headerGroups}
                                        rowModel={rowModel}
                                    />
                                </TableStyled>
                            </TableContainer>
                        ) : (
                            <NothingToShow>
                                <TB>{t("no_requisitions_to_show")}</TB>
                            </NothingToShow>
                        )}
                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </QueryWrapper>
                </form>
            </FormProvider>

            {isTaskEditorOpen && (
                <TaskEdit
                    editTaskId={editTaskId}
                    isOpen={isTaskEditorOpen}
                    onClose={closeTaskEditor}
                    initialData={taskEditorInitialData}
                    resetTaskEditor={resetTaskEditor}
                />
            )}
        </MainArea>
    );
};

export default InventoryWithdrawal;

const Header = styled(Horizontal)`
    align-items: center;
    flex-wrap: wrap;
`;

const CheckboxInputContainer = styled.section`
    position: relative;
`;

const StatusActionButton = styled(SecondaryButton).attrs({ type: "button" })`
    padding-top: 0.7rem;
    margin: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;
`;

const SelectStyled = styled(SelectForm)`
    min-width: 10rem;
    margin: 0.3rem 0;
`;

const OptionsRow = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    ${SelectStyled} {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
`;

const TableContainer = styled.article`
    margin-top: 1rem;
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 75rem;

    td:first-child,
    th:first-child {
        min-width: 1rem;
        max-width: 2rem;
    }

    tr {
        td:nth-child(4) {
            padding-right: 2rem;
        }

        td:nth-child(12),
        td:nth-child(13),
        td:nth-child(14),
        td:nth-child(15) {
            max-width: 10rem;
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const SelectWrapper = styled.section`
    width: min(25rem, 100%);
    margin-top: 1rem;
    margin-bottom: 1rem;
`;
